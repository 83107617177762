.white-bg-lowerbar {
  position: absolute;
  left: 477px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 116px;
  background-color: var(--white);
}

.blue-bg-lowerbar {
  position: absolute;
  left: 475px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 116px;
  background-color: var(--ciBlue);
}

.white-bg-lowerbar-orange-line {
  position: absolute;
  left: 477px;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 116px;
  background-color: var(--white);
  border-bottom: 26px solid var(--ciOrange);
}

.img-bg-lowerbar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 267px;
  z-index: 1;
  background-color: var(--white);
}